<template>
  <div class="main-content">
    <h3 class="page-heading">Transactions</h3>

    <div class="cards-container column" :class="layout">
      <DateRanger
        :startDate="this.dateFrom"
        :endDate="this.dateTo"
        @update="updateDateRange" />

      <div class="section-wrapper row align-items-center transactions">
        <div class="centered-loading-wrapper column" v-if="loadingChartData">
          <LoadingSpinner />
          <p>Loading summary...</p>
        </div>
        <template v-else>
          <section class="headline-figures row stretch full-width">
            <Card :flex="6" title="Top 5 Customers" class="top-5-card">
              <template v-slot:content>
                <div class="full-width">
                  <div
                    class="full-width"
                    v-if="topFiveCustomerData?.length > 0">
                    <AgGridVue
                      style="width: 100%; height: 350px"
                      class="ag-theme-alpine"
                      :class="{ 'no-data': !rowData }"
                      enableCellTextSelection="true"
                      ensureDomOrder="true"
                      :columnDefs="columnDefs"
                      :getRowStyle="getRowStyle"
                      :rowData="rowData">
                    </AgGridVue>
                  </div>
                  <NoDataWrap v-else>No Data</NoDataWrap>
                </div>
              </template>
            </Card>
            <Card
              :flex="4"
              title="Order Distribution"
              :tabletFull="false"
              class="transactions-orders">
              <template v-slot:content>
                <div class="full-width full-height">
                  <div
                    class="full-width full-height column"
                    v-if="orderChannelChartData?.labels.length > 0">
                    <div class="summary-total row baseline no-wrap">
                      <p class="summary-title">Total Orders</p>
                      <p class="summary-value column no-gap">
                        <span class="orders-quantity">
                          {{ orderSummaryData.orders }}
                        </span>
                        <span class="orders-gross">
                          {{ orderSummaryData.orderTotal }}
                        </span>
                      </p>
                    </div>
                    <DoughnutChart
                      :chartData="orderChannelChartData"
                      :aspectRatio="1.6"
                      :showLegend="true"
                      legendPosition="bottom" />
                  </div>
                  <NoDataWrap v-else>No Data</NoDataWrap>
                </div>
              </template>
            </Card>
            <Card
              :flex="4"
              title="Payment Type"
              class="transactions-orders"
              :tabletFull="false">
              <template v-slot:content>
                <div class="full-width full-height">
                  <div
                    class="full-width full-height row"
                    v-if="paymentChannelData?.labels.length > 0">
                    <DoughnutChart
                      :chartData="paymentChannelData"
                      :aspectRatio="1.6"
                      :showLegend="true"
                      legendPosition="bottom" />
                  </div>
                  <NoDataWrap v-else>No Data</NoDataWrap>
                </div>
              </template>
            </Card>
          </section>

          <section
            class="headline-figures row stretch no-wrap full-width transactions-revenue">
            <div
              class="centered-loading-wrapper column"
              v-if="loadingRevenueData">
              <LoadingSpinner />
              <p>Loading summary...</p>
            </div>
            <Card :flex="12" :tabletFull="false" v-if="!loadingRevenueData">
              <template v-slot:content>
                <!-- <ValueCard
                  title="Average Spend"
                  :value="
                    formatMoney(transactionChartData.ordersAmount.average)
                  " /> -->
                <ValueCard
                  title="Total Payments"
                  :value="
                    formatMoney(transactionChartData.paymentsAmount.total)
                  " />
                <ValueCard
                  title="Gross revenue"
                  :value="
                    formatMoney(transactionChartData.grossRevenueAmount.total)
                  " />
                <ValueCard
                  title="Refunds"
                  :value="formatMoney(refunds?.value)"
                  :subValue="formatPercent(refunds?.percent)"
                  :subValueAlert="refunds?.percent > 3"
                  :subValueSafe="refunds?.percent < 1" />
                <ValueCard
                  title="Cancellations"
                  :value="formatMoney(cancellations?.value)"
                  :subValue="formatPercent(cancellations?.percent)"
                  :subValueAlert="cancellations?.percent > 3"
                  :subValueSafe="cancellations?.percent < 1" />
                <ValueCard
                  title="Net Revenue"
                  :value="formatMoney(netRevenue?.value)"
                  :subValue="formatPercent(netRevenue?.percent)"
                  :subValueAlert="netRevenue?.percent < 95"
                  :subValueSafe="netRevenue?.percent > 98" />
              </template>
            </Card>
          </section>
        </template>
      </div>

      <TransactionLog :rowdataProp="transactionChartData?.transactions" />
    </div>
  </div>
</template>

<script>
  import { AgGridVue } from "ag-grid-vue3";
  import { screenDetector } from "@/helpers/screenDetector";
  import TransactionLog from "@/components/TransactionLog";
  import Card from "@/components/Card.vue";
  import { pieChartColours } from "@/helpers/chartColours";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import NoDataWrap from "@/components/NoDataWrap.vue";
  import { getTransactionEndpoint } from "@/helpers/transactions";
  import DoughnutChart from "@/components/charts/DoughnutChart";
  import DateRanger from "@/components/Inputs/DateRanger";
  import ValueCard from "@/components/CardViews/ValueCard";
  import {
    APIORDER_CHANNEL,
    APIPAYMENT_TYPE,
    TransactionGraph,
  } from "@tucktrucks/platform-base-private";
  import router from "@/router";

  export default {
    components: {
      TransactionLog,
      Card,
      LoadingSpinner,
      NoDataWrap,
      DoughnutChart,
      AgGridVue,
      DateRanger,
      ValueCard,
    },
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        dateFrom: this.$route.params.dateFrom,
        dateTo: this.$route.params.dateTo,

        showChart: true,
        layout: "desktop",
        loading: false,
        topFiveCustomerData: null,
        orderSummaryData: null,
        orderChannelChartData: null,
        paymentChannelData: null,
        averageSpend: null,
        getRowStyle: null,
        rowData: null,
        grossRevenue: null,
        refunds: null,
        cancellations: null,
        netRevenue: null,

        loadingChartData: true,
        loadingRevenueData: false,
        initialDateRangeUsed: false,
        initialDateRangeReady: false,
        transactionChartData: null,
      };
    },

    computed: {
      columnDefs() {
        return [
          {
            headerName: "Customer",
            field: "email",
            resizable: true,
            wrapText: true,
            flex: 1,
            autoHeight: true,
            cellClass: "top-5",
            cellRenderer: (params) => {
              return `<span class="name">${params.data.name}</span>
                 <span class="detail column"><span class="order-count">
                  <span class="mdi-icon" role="img"><svg fill="#999" class="material-design-icon__svg" width="16" height="16"viewBox="0 0 24 24"><path d="M18.06 23H19.72C20.56 23 21.25 22.35 21.35 21.53L23 5.05H18V1H16.03V5.05H11.06L11.36 7.39C13.07 7.86 14.67 8.71 15.63 9.65C17.07 11.07 18.06 12.54 18.06 14.94V23M1 22V21H16.03V22C16.03 22.54 15.58 23 15 23H2C1.45 23 1 22.54 1 22M16.03 15C16.03 7 1 7 1 15H16.03M1 17H16V19H1V17Z" /></svg></span>
                 Orders: ${params.data.orders.length}</span>
                 <span class="email"><span class="mdi-icon" role="img"><svg fill="#999" class="material-design-icon__svg" width="16" height="16"viewBox="0 0 24 24"><path d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" /></svg></span>
                ${params.value}</span></span>`;
            },
          },
          {
            headerName: "Total Spend",
            field: "paymentsAmount.total",
            resizable: true,
            width: 120,
            suppressSizeToFit: true,

            cellRenderer: (params) => {
              return `<span class="top-5-cust-ord-total-spend">
                ${this.formatMoney(params.value)}</span>`;
            },
          },
        ];
      },
    },

    watch: {
      initialDateRangeReady() {
        if (this.initialDateRangeReady) {
          this.getData();
          this.initialDateRangeUsed = true;
        }
      },
    },

    methods: {
      updateIfDesktopLayoutState() {
        this.layout = screenDetector();
      },

      updateDateRange(data) {
        router.push({
          name: "/sales/transactions/dates",
          params: {
            operatorType: this.operatorType,
            operatorId: this.operatorId,
            dateFrom: data.startDate,
            dateTo: data.endDate,
          },
        });
      },

      getData() {
        this.loadingChartData = true;

        getTransactionEndpoint(this.operatorType, this.operatorId, {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        })
          .then((response) => {
            if (response.status == 200) {
              return TransactionGraph.create(response.data.data).then(
                (data) => {
                  return data;
                }
              );
            }

            return Promise.reject("Could not download transaction summary.");
          })
          .then((data) => {
            this.transactionChartData = data;
            this.loadingChartData = false;

            this.setUpTopFiveCustomer(data);
            this.setOrderSummaryData(data);
            this.setUpAverageAndRevenue(data);
            this.setUpOrderChannel(data);
            this.setPaymentChannelData(data);
            this.setUpGrossRevenueBreakdown(data);
          })
          .catch((err) => {
            window.log.error(err);
          });
      },

      setUpTopFiveCustomer(data) {
        this.topFiveCustomerData = data.customers
          .sort((a, b) => a.paymentsAmount.total < b.paymentsAmount.total)
          .slice(0, 5);
        this.rowData = this.topFiveCustomerData;
      },

      setOrderSummaryData(data) {
        this.orderSummaryData = {
          orders: data.ordersAmount.count,
          orderTotal: this.formatMoney(data.ordersAmount.total),
        };
      },

      setUpAverageAndRevenue(data) {
        this.averageSpend = data.ordersAmount.mean();
      },

      setUpGrossRevenueBreakdown(data) {
        this.grossRevenue = data.grossRevenue;
        this.refunds = {
          value: data.refundsAmount.total,
          percent:
            data.grossRevenueAmount.total > 0
              ? (data.refundsAmount.total / data.grossRevenueAmount.total) * 100
              : 0,
        };

        this.cancellations = {
          value: data.cancellationsAmount.total,
          percent:
            data.grossRevenueAmount.total > 0
              ? (data.cancellationsAmount.total /
                  data.grossRevenueAmount.total) *
                100
              : 0,
        };

        const netRevenueAmount =
          data.grossRevenueAmount.total -
          (data.refundsAmount.total + data.cancellationsAmount.total);

        this.netRevenue = {
          value: netRevenueAmount,
          percent:
            data.grossRevenueAmount.total > 0
              ? (netRevenueAmount / data.grossRevenueAmount.total) * 100
              : 0,
        };
      },

      setUpOrderChannel(data) {
        this.orderChannelChartData = this.generateDataforChart([
          this.createGraphItem(
            "Click & Collect",
            data.ordersByChannel[APIORDER_CHANNEL.CLICK_COLLECT]
          ),
          this.createGraphItem(
            "Walk Up",
            data.ordersByChannel[APIORDER_CHANNEL.WALK_UP]
          ),
          this.createGraphItem(
            "Delivery",
            data.ordersByChannel[APIORDER_CHANNEL.DELIVERY]
          ),
          this.createGraphItem(
            "Phone",
            data.ordersByChannel[APIORDER_CHANNEL.PHONE]
          ),
          this.createGraphItem(
            "Table Service",
            data.ordersByChannel[APIORDER_CHANNEL.TABLE_SERVICE]
          ),
        ]);
      },

      setPaymentChannelData(data) {
        this.paymentChannelData = this.generateDataforChart([
          this.createGraphItem(
            "Cash",
            data.paymentsByType[APIPAYMENT_TYPE.CASH]
          ),
          this.createGraphItem(
            "Stripe",
            data.paymentsByType[APIPAYMENT_TYPE.STRIPE]
          ),
          this.createGraphItem(
            "Zettle",
            data.paymentsByType[APIPAYMENT_TYPE.ZETTLE]
          ),
          this.createGraphItem(
            "Manual Card",
            data.paymentsByType[APIPAYMENT_TYPE.EXTERNAL]
          ),
          this.createGraphItem(
            "Promotion",
            data.paymentsByType[APIPAYMENT_TYPE.PROMOTION_VOUCHER]
          ),
        ]);
      },

      createGraphItem(name, array) {
        array ??= [];

        return {
          name: name,
          count: array.length,
          value: array.reduce(
            (sum, current) =>
              sum +
              (current.transactionsTotal ??
                current.receivedAmount ??
                current.grossRevenue ??
                0),
            0
          ),
        };
      },

      generateDataforChart(data) {
        data = data.filter((x) => x.count != 0);

        return {
          labels: data.map((item) => `${item.name} (${item.count})`),
          datasets: [
            {
              data: data.map((item) => item.count),
              backgroundColor: pieChartColours(data.length),
              value: data.map((item) => item.value),
            },
          ],
        };
      },
    },

    beforeMount() {
      this.updateIfDesktopLayoutState();
      window.addEventListener("resize", this.updateIfDesktopLayoutState);

      this.initialDateRangeReady = true;

      // set background colour on even rows again, this looks bad, should be using CSS classes
      this.getRowStyle = (params) => {
        if (params.node.rowIndex % 2 !== 0) {
          return { background: "#fbfbfb" };
        }
      };
    },
    unmounted() {
      window.removeEventListener("resize", this.updateIfDesktopLayoutState);
    },
  };
</script>

<style lang="scss">
  .revenue-payout-summary {
    display: flex;
    flex-direction: row;
    &__data {
      width: 50%;
      max-width: 800px;
      min-width: 300px;
      .xl-desktop &,
      .desktop & {
        margin-left: 4rem;
      }
    }
    &.tablet,
    &.mobile {
      flex-direction: column;
    }
    &__title,
    &__sub-title {
      color: #aaa;
      margin: 0;
    }
    &__value {
      align-items: end;
      height: 50px;
      p {
        font-size: 2rem;
        margin: 0;
      }
      span.percentage {
        margin-left: 1rem;
        line-height: 2rem;
      }
    }
    &__sub-title {
      font-size: 0.7rem !important;
      margin-bottom: 0.1rem !important;
    }
    &__sub-value {
      align-items: center;
      p {
        font-size: 1.6rem;
        margin: 0;
      }
    }
    &__revenue-content {
      align-items: flex-end;
      & div:first-child {
        .xl-desktop &,
        .desktop {
          margin-right: 3rem;
        }
      }
    }
  }

  .transactions-revenue {
    .card__title {
      min-height: 45px;
    }
    .card__content {
      align-items: flex-start !important;
      justify-content: space-between;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      padding-top: 1rem;
      @media screen and (max-width: $vertical_tablet_width) {
        grid-template-columns: repeat(2, 1fr);
      }
      .highlighted-value__item {
        height: 100%;
      }
    }
  }

  span.growth-percentage-chip {
    background: $col_beta;
    border-radius: $card_radius;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0 1rem;
    &.declined {
      background: $col_alpha;
    }
  }

  .transactions h4 {
    font-size: 1.8rem !important;
    .loading-spinner {
      width: 30px;
      height: 30px;
    }
  }

  .transactions-orders {
    .card__content {
      align-items: flex-end !important;
    }
    .chart.doughnut {
      width: 70%;
      margin: 0 auto;
    }
    .summary-total {
      justify-content: space-between;
      width: 100%;
    }
    .summary-value {
      width: 150px;

      @media screen and (max-width: $landscape_tablet_width) {
        width: 90px;
      }
    }
    span.orders-quantity {
      font-size: 1.5rem;
      display: inline-block;
      margin-bottom: 0.5rem;
    }
    span.orders-gross {
      font-size: 1rem;
    }
  }

  section.headline-figures {
    margin: 0;
  }

  .chart-view {
    width: 20%;
  }

  .tablet .chart.doughnut,
  .mobile .chart.doughnut {
    @media screen and (max-width: 1279px) {
      width: 100% !important;
      margin: 0 auto !important;
    }
  }

  .top-5-card {
    .card__content {
      align-items: baseline;
    }
    .ag-row {
      cursor: text !important;
      &:hover {
        background: #e8e8e8 !important;
      }
    }
    .top-5 .ag-cell-wrapper {
      color: #000;
      padding: 0.5rem 0;
      .name {
        font-size: 1.1rem;
        line-height: 1.3rem;
        margin-bottom: 0.25rem;
        display: block;
      }
      .detail {
        gap: unset;
        line-height: 1.3rem;
        flex-wrap: nowrap;
        @media screen and (max-width: $landscape-tablet-width) {
          flex-direction: column;
          align-items: baseline;
        }
      }
      .order-count,
      .email {
        font-size: 0.8rem;
        color: #999;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 0.25rem;
      }

      span.material-icons-round {
        font-size: 0.8rem;
      }

      .email span.material-icons-round {
        padding-top: 3px;
      }
    }
    .ag-cell[col-id="totalSpent"] {
      .ag-cell-value {
        height: 100%;
      }
      .top-5-cust-ord-total-spend {
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }

  .netrevenue h4,
  .averagespend h4 {
    font-size: 2rem;
  }

  .section-wrapper {
    min-height: 300px;
    width: 100%;
    .centered-loading-wrapper {
      box-shadow: $card_shadow;
      border-radius: $card_radius;
      min-height: 290px;
    }
  }
</style>
