<template>
  <div class="grid-view">
    <AgGridVue
      style="width: width; min-height: minHeight"
      class="ag-theme-alpine"
      @gridReady="onGridReady"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :rowSelection="'single'"
      :pagination="true"
      :paginationAutoPageSize="true"
      :loadingOverlayComponent="loadingOverlayComponent">
    </AgGridVue>
    <p class="error grid-error-overlay" v-if="errorTextFromAPI">
      {{ errorTextFromAPI }}
    </p>
  </div>
</template>

<script>
  import { AgGridVue } from "ag-grid-vue3";
  import { stringToClassName } from "@/helpers/stringFormatter";
  import { APIPAYMENT_TYPE } from "@tucktrucks/platform-base-private";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";

  export default {
    components: {
      AgGridVue,
      /* eslint-disable vue/no-unused-components */
      LoadingSpinner: LoadingSpinner,
    },
    props: {
      minHeight: {
        type: String,
        default: "700px",
      },
      rowdataProp: null,
    },
    watch: {
      rowdataProp(newValue) {
        this.rowData = newValue;
        this.clearError();
      },
    },
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        errorTextFromAPI: null,
        gridApi: null,
        gridColumnApi: null,
        rowData: this.rowdataProp,
        loadingOverlayComponent: "LoadingSpinner",
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: true,
          minWidth: 100,
          width: 150,
        },
        columnDefs: [
          {
            headerName: "Order ID",
            field: "orderId",
            pinned: "left",
            width: 120,
          },
          {
            headerName: "Outlet",
            field: "order.outlet.name",
            hide: this.$route.params.operatorType == "outlet",
          },
          {
            headerName: "Venue",
            field: "order.venue.name",
            hide: this.$route.params.operatorType == "venue",
          },
          {
            headerName: "Order Channel",
            field: "order.channel",
            cellClass: ({ value }) => stringToClassName(value),
          },
          {
            headerName: "Order Status",
            field: "order.status",
            cellRenderer: ({ data, value }) => {
              return `<span class="top-5-cust-ord-total-spend chip">${value}</span>${this.getOrderPlacedAtContent(
                data
              )}`;
            },
            cellClass: ({ value }) => stringToClassName(value),
          },
          {
            headerName: "Name",
            field: "customer.name",
            cellClass: ({ value }) => value || "no-guest-name",
            cellRenderer: ({ value }) =>
              value?.trim().length ? value : "Guest",
          },
          {
            headerName: "Email",
            field: "customer.email",
            cellClass: ({ value }) => value || "no-guest-email",
            cellRenderer: ({ value }) => (value?.trim().length ? value : "-"),
          },
          {
            headerName: "Placed",
            field: "order.placedAt",
            valueFormatter: ({ value }) => this.formatDateTime(value),
          },
          {
            headerName: "Completed",
            field: "order.completedAt",
            valueFormatter: ({ value }) => this.formatDateTime(value),
          },
          {
            headerName: "Amount Cash",
            field: "order.payments",
            valueFormatter: ({ value }) => {
              return this.formatMoney(
                value
                  .filter((x) => x.type == APIPAYMENT_TYPE.CASH)
                  .reduce((t, i) => t + i.receivedAmount, 0)
              );
            },
          },
          {
            headerName: "Amount Card",
            field: "order.payments",
            valueFormatter: ({ value }) => {
              return this.formatMoney(
                value
                  .filter(
                    (x) =>
                      x.type == APIPAYMENT_TYPE.STRIPE ||
                      x.type == APIPAYMENT_TYPE.ZETTLE ||
                      x.type == APIPAYMENT_TYPE.EXTERNAL_CARD
                  )
                  .reduce((t, i) => t + i.receivedAmount, 0)
              );
            },
          },
          {
            headerName: "Gross Revenue",
            field: "order.grossRevenue",
            valueFormatter: ({ value }) => this.formatMoney(value),
          },
        ],
      };
    },
    methods: {
      onGridReady(params) {
        // get the grid and column api
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.hideLoading();
      },

      getOrderPlacedAtContent(order) {
        if (order.orderedForFutureService) {
          return `<span class="top-5-cust-ord-total-spend chip chip-blue">Pre-ordered for ${this.formatDate(
            order.serviceDate,
            "dd/MM/yyyy"
          )}</span>`;
        }
        return "";
      },

      clearError() {
        this.errorTextFromAPI = null;
      },

      showLoading() {
        this.gridApi.showLoadingOverlay();
      },

      hideLoading() {
        this.gridApi.hideOverlay();
      },
    },

    beforeUpdate() {
      this.showLoading();
    },

    updated() {
      this.hideLoading();
    },
  };
</script>
