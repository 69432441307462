import store from "@/store";

export function getTransactionEndpoint(operatorType, operatorId, request) {
  const params = {
    includePayments: true,
    includePaymentIntents: false,
    includeCarts: false,
    includeOrders: true,
    includeRefunds: true,
    includeCustomers: true,
    includeOutlets: true,
    includeVenues: true,
    dateFrom: request.dateFrom,
    dateTo: request.dateTo,
    services: request.services,
    events: request.events,
  };

  switch (operatorType) {
    case "vendor":
      params.vendorIds = [operatorId];
      break;
    case "outlet":
      params.outletIds = [operatorId];
      break;
    case "venue":
      params.venueIds = [operatorId];
      break;
    default:
      break;
  }

  return store.state.apiPrivate.client.endpoints.transactions.query(params);
}
