<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
    class="chart doughnut" />
</template>

<script>
  import { Doughnut } from "vue-chartjs";
  import { chartLabelFormatter } from "@/helpers/chartHandlers.js";
  import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
  } from "chart.js";

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
    ChartJSPluginDatalabels
  );

  export default {
    name: "DoughnutChart",
    components: { Doughnut },
    props: {
      chartData: {
        type: Object,
      },
      chartId: {
        type: String,
        default: "doughnut-chart",
      },
      datasetIdKey: {
        type: String,
        default: "label",
      },
      width: {
        type: Number,
        default: 400,
      },
      height: {
        type: Number,
        default: 400,
      },
      cssClasses: {
        default: "",
        type: String,
      },
      styles: {
        type: Object,
        default: () => {},
      },
      plugins: {
        type: Object,
        default: () => {},
      },
      responsive: {
        type: Boolean,
        default: true,
      },
      aspectRatio: {
        type: Number,
        default: 1.6,
      },
      showLegend: {
        type: Boolean,
        default: false,
      },
      legendPosition: {
        type: String,
        default: "bottom",
      },
      maintainAspectRatio: {
        type: Boolean,
        default: false,
      },
      showDatalabels: {
        type: Boolean,
        default: false,
      },
      formatMoneyForLabel: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        chartOptions: {
          responsive: this.responsive,
          aspectRatio: this.aspectRatio,
          maintainAspectRatio: this.maintainAspectRatio,
          plugins: {
            legend: {
              display: this.showLegend,
              position: this.legendPosition,
              labels: {
                boxWidth: 20,
                padding: 5,
                font: {
                  size: 11,
                },
              },
            },
            datalabels: {
              display: this.showDatalabels,
              color: "white",
              font: {
                weight: "bold",
              },
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) =>
                  chartLabelFormatter(
                    tooltipItem,
                    this.formatMoney,
                    this.formatMoneyForLabel
                  ),
              },
            },
          },
        },
      };
    },
  };
</script>
